
@font-face {
  font-family: 'Nexa';
  src: url('assets/fonts/nexa-light.otf');
  font-weight: 300;
}

@font-face {
  font-family: 'Nexa';
  src: url('assets/fonts/nexa-regular.otf');
  font-weight: 400;
}

@font-face {
  font-family: 'Nexa';
  src: url('assets/fonts/nexa-bold.otf');
  font-weight: 600;
}